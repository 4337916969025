import axios, {AxiosResponse} from "axios";
import {CreateUserModel, EditUserModel, PostPermissionsModel, UserOverviewModel, VideoOptionsModel} from "./models";
import {KEYCLOAK_BASE_ADDRESS, VIMEO_BASE_ADDRESS} from "../../constants";
import {GetKeycloakRequestHeaders} from "../../helpers/tokenHelpers";

export const FetchUserDetail = (userId?: string):Promise<AxiosResponse<EditUserModel>> =>  {
    return axios.get<EditUserModel>(`${KEYCLOAK_BASE_ADDRESS}/auth/admin/realms/MovieStreamer/users/${userId}`, { headers: GetKeycloakRequestHeaders() })
}
export const SaveUserDetail = (model?: EditUserModel, userId?: string):Promise<AxiosResponse> => {
    return axios.put(`${KEYCLOAK_BASE_ADDRESS}/auth/admin/realms/MovieStreamer/users/${userId}`, { ...model }, { headers: GetKeycloakRequestHeaders()})
}

export const CreateUser = (model?: CreateUserModel): Promise<AxiosResponse> => {
    return axios.post(`${KEYCLOAK_BASE_ADDRESS}/auth/admin/realms/MovieStreamer/users`, { ...model }, { headers: GetKeycloakRequestHeaders()})
}

export const DeleteUser = (userId?: string):Promise<AxiosResponse> => {
    return axios.delete(`${KEYCLOAK_BASE_ADDRESS}/auth/admin/realms/MovieStreamer/users/${userId}`,  { headers: GetKeycloakRequestHeaders()})
}

export const FetchUsers = ():Promise<AxiosResponse<UserOverviewModel[]>> =>  {
    return axios.get<UserOverviewModel[]>(`${KEYCLOAK_BASE_ADDRESS}/auth/admin/realms/MovieStreamer/users?max=10000`, { headers: GetKeycloakRequestHeaders() })
}
export const FetchVideos = ():Promise<AxiosResponse<VideoOptionsModel[]>> =>  {
    return axios.get<VideoOptionsModel[]>(`${VIMEO_BASE_ADDRESS}/admin/videos`, { headers: GetKeycloakRequestHeaders() })
}

export const FetchVideoPermissions = (userId: string):Promise<AxiosResponse<string[]>> => {
    return axios.get<string[]>(`${VIMEO_BASE_ADDRESS}/admin/videoPermissions?userId=${userId}`, { headers: GetKeycloakRequestHeaders() })
}
export const PostVideoPermissions = (permissionModel: PostPermissionsModel):Promise<AxiosResponse> => {
    return axios.post(`${VIMEO_BASE_ADDRESS}/admin/videoPermissions`, {...permissionModel} , { headers: GetKeycloakRequestHeaders()})
}
